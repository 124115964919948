import React from 'react'
import { node } from 'prop-types'

import styles from './ButtonStandardArrow.module.scss'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Icon from '@/component/Primitive/Icon'

/**
 * Wrapper around a ButtonStandard component, specifically adding the commonly
 * used arrow icon.
 */

const ButtonStandardArrow = ({ children, ...other }) => (
  <ButtonStandard {...other}>
    {children}&nbsp;
    <span className={styles.ButtonStandardArrowIcon}>
      <Icon type="arrow-right" a11yText="" />
    </span>
  </ButtonStandard>
)

ButtonStandardArrow.propTypes = {
  children: node
}

export default ButtonStandardArrow
