import { parse, format, add } from 'date-fns'
import email from 'email-validator'

const apiUrl =
  process.env.API_URL || 'http://localhost.clockhosting.com:7668/rtc/'
const isoDateWithoutTimeChangeFormat = `yyyy-MM-dd'T'HH:mm:ss.000`

const fetch = async (url, opts = { headers: {}, registration: null }) => {
  const registration =
    typeof window !== 'undefined'
      ? sessionStorage.getItem('registration')
      : opts.registration

  const options = {
    ...opts,
    headers: {
      ...opts.headers,
      'x-registration': registration
    }
  }

  return await global.fetch(url, options)
}

const postJSON = (url, data) =>
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

const getData = async (response) => {
  if (response.error) throw new Error(response.error)
  const data = await response.json()

  if (data.error) {
    const { req, res } = data
    if (req) console.log('Request: ', req)
    if (res) console.log('Response: ', res)

    if (data.error) throw new Error(data.message)
  }

  return data
}

const getResult = async (response) => {
  if (response.error) throw new Error(response.error)

  const data = await response.json()

  return data
}

const getResultAndLog = async (response) => {
  const { req, res, result } = await getData(response)

  if (req) console.log('Request: ', req)
  if (res) console.log('Response: ', res)

  return result
}

export const getDealers = async () => {
  const response = await fetch(`${apiUrl}dealers`)

  return await getResultAndLog(response)
}

export const getDealerInfo = async (dealerId) => {
  const response = await fetch(`${apiUrl}${dealerId}`)

  return await getResultAndLog(response)
}

export const getModels = async (dealerId) => {
  if (!dealerId) return

  const response = await fetch(`${apiUrl}${dealerId}/models`)

  return await getResultAndLog(response)
}

export const getPaymentDetails = async (dealerId) => {
  if (!dealerId) return

  const response = await fetch(`${apiUrl}${dealerId}/payment-methods`)

  return await getResultAndLog(response)
}

export const lookupVehicle = async (reg) => {
  if (!reg) return
  const response = await fetch(`${apiUrl}lookup-vehicle?registration=${reg}`, {
    registration: reg
  })

  return await getResultAndLog(response)
}

const serviceToApi = (service) => ({
  Code: service.id,
  AllowedTime: service.time,
  Price: service.priceOriginal
})

export const getServices = async (dealerId, model) => {
  if (!dealerId || !model) return

  const response = await fetch(
    `${apiUrl}${dealerId}/repairs-list?model=${model}`
  )

  const result = await getResult(response)

  const formatted = result
    .filter((i) => i.GroupId !== 'Special')
    .map((group) => ({
      title: group.Description,
      items: group.operations.map((operation) => ({
        title: operation.Description,
        description: operation.Information,
        price: `£${operation.Price}`,
        priceOriginal: operation.Price,
        id: operation.Code,
        time: operation.AllowedTime,
        priceNumber: Number.parseFloat(operation.Price)
      }))
    }))

  return {
    services: formatted,
    investigation: result.filter((i) => i.GroupId === 'Special')[0]
  }
}

export const getAvailability = async (
  dealerId,
  date,
  repairs,
  filters = {},
  other = {}
) => {
  const response = await postJSON(`${apiUrl}${dealerId}/availability`, {
    date,
    repairs: repairs.map(serviceToApi),
    filters,
    ...other
  })

  return await getResultAndLog(response)
}

export const getInsuranceQuestions = async (dealerId, selfInsure, dob) => {
  const response = await postJSON(`${apiUrl}${dealerId}/insurance-questions`, {
    selfInsure,
    date: dob
  })

  return await getResultAndLog(response)
}

export const createBooking = async (dealerId, form) => {
  const { details, vehicle, services, booking } = form

  const hasMot = services.selected.find((service) => service.id.includes('MOT'))

  const [hours, minutes = '0'] = booking.arrivalTime.split('.')

  const appointment = add(booking.date, { hours, minutes })

  if (!email.validate(details.emailAddress)) {
    return {
      message: 'This email is invalid. Please try again.'
    }
  }

  const bookingObj = {
    createBookingRequest: {
      AppointmentTime: format(appointment, isoDateWithoutTimeChangeFormat),
      CourtesyLift: !!(booking.filters && booking.filters.hasLift),
      CourtesyVehicle: !!(booking.filters && booking.filters.hasCourtesy),
      CourtesyVehicleCharge: 0.0, // TODO: what's this value?
      CourtesyVehicleSelfInsure: !!booking.selfInsure,
      CustomSlot: false,
      Mot: !!hasMot,
      PaymentMethod: details.paymentMethod,
      PaymentComment: details.paymentInformation,
      WhileYouWait: !!(booking.filters && booking.filters.waitWithVehicle),
      CourtesyVehicleType: '',
      Customer: {
        Title: details.title,
        Initials:
          details.forenames
            .split(' ')
            .map((name) => name[0])
            .join('') + details.surname[0],
        Email: details.emailAddress,
        Forename: details.forenames,
        Surname: details.surname,
        Salutation: details.forenames + ' ' + details.surname,
        Address1: details.address.addressLine1,
        Address2: details.address.addressLine2,
        Address3: details.address.addressLine3,
        Address4: details.address.town,
        Address5: details.address.cityCounty,
        Postcode: details.address.postcode,
        Phone3: details.phoneNumber,
        PreferredPhone: 3,
        CourtesyVehicleDriverDOB: format(
          parse(
            booking.dob
              ? `${booking.dob.year}-${booking.dob.month}-${booking.dob.day}`
              : '1992-A-15',
            'yyyy-LLLL-d',
            new Date()
          ),
          isoDateWithoutTimeChangeFormat
        )
      },
      Vehicle: {
        ...vehicle.fullDetails,
        Description: vehicle.name,
        Colour: vehicle.color,
        Model: vehicle.model
      },
      RepairOperations: {
        RepairOperation: services.selected.map((selected) =>
          serviceToApi(selected)
        )
      }
    }
  }

  const { code, text, allowedTime } = services.investigation

  if (code && text) {
    bookingObj.createBookingRequest.RepairOperations.RepairOperation.push({
      Code: code,
      Text1: text,
      Price: 0,
      AllowedTime: allowedTime
    })
  }

  if (booking.facility === 'hasCollection')
    bookingObj.createBookingRequest.ColDelDetails = [
      {
        ColDelDetail: {
          Address1: booking.address.addressLine1,
          Address2: booking.address.addressLine2,
          Address3: booking.address.addressLine3,
          Address4: booking.address.town,
          Address5: booking.address.cityCounty,
          Postcode: booking.address.postcode,
          ColDelType: 'Collection'
        }
      }
    ]

  bookingObj.Facility = booking.facility

  const response = await postJSON(`${apiUrl}${dealerId}/booking`, bookingObj)
  const data = await response.json()
  const { req, res, result } = data

  if (req) console.log('Request: ', req)
  if (res) console.log('Response: ', res)
  if (data && data.error) {
    if (data.canRecover) {
      return data
    }
    throw new Error(data.message)
  }

  return result
}
